<template>
    <v-col ml-1 mt-2 mb-2>
        <v-row row wrap>
            <v-col cols="12">
                <div v-show="event.status=='Draft' && !isDashboard" class="font-weight-bold text-center">ADMIN PREVIEW</div>
            </v-col>
            <v-col cols="12">
                <div class="headline mb-n5">{{ event.name }}</div>
            </v-col>

            <v-col cols="11">
                <div class="font-weight-light text-xs-left"> {{shortDate(event.start)}} until {{shortDate(event.stop)}}</div>
            </v-col>
            <v-col cols="12">

                <div class="mt-2 mb-n2"><strong>Will you be attending?</strong></div>

                <v-radio-group
                v-model="rsvp"
                column
                >
                <v-radio
                    color="success"
                    value="yes"
                >
                    <template v-slot:label>
                        <div v-if="rsvp=='yes'"><strong class="success--text">YES (me)</strong></div>
                        <div v-else>YES (me)</div>
                    </template>
                </v-radio>
               
                <v-radio
                    color="success"
                    value="yes-plus"
                >
                    <template v-slot:label>
                        <div v-if="rsvp=='yes-plus'"><strong class="success--text">YES (me +1)</strong></div>
                        <div v-else>YES (me +1)</div>
                    </template>
                </v-radio>

               <v-radio
                    label="I cannot make it"
                    color="error"
                    value="no"
                >
                    <template v-slot:label>
                        <div v-if="rsvp=='no'"><strong class="error--text">NO</strong></div>
                        <div v-else>NO</div>
                    </template>
                    </v-radio>
                </v-radio-group>

            </v-col>
            <v-select dense outlined v-if="rsvp=='yes'" label="# attending" v-model="attending" :choices="[1,2]" ></v-select>

            <v-col cols="11">
                
                <div class="font-weight-bold text-xs-left" :style="{ color : rsvpColor }"><v-icon>{{rsvpIcon}}</v-icon>{{rsvpStatus}}</div>
            </v-col>
        </v-row>
    </v-col>
</template>

<script>
import moment from 'moment-timezone'
export default {
    props: {
        event: {type: Object, required: true},
        hideDateDetail: {type: Boolean, required: false},
        isDashboard: {type: Boolean, required: false}
    },
    computed: {
        rsvpClosed () { return this.closed(this.event.start)},
        userEvent () { 
            let eventRef = "event-" + this.event.id
            let userEvent = this.$store.state.userInfo[eventRef]
            return userEvent
        },
        rsvpStatus () {
            if(this.userEvent)
                return "Reservation Booked"
            else
                return "Reservation Required"
        },
        rsvpIcon () {
            if(this.userEvent)
                return "check_box"
        },
        rsvpColor () {
            if(this.userEvent)
                return "green"
            else    
                return "red"
        }
    },
    methods: {
        closed (dateStr) {
            let diff = moment(dateStr, "YYYY-MM-DD @HH:mm").diff(moment(), "hours")
            return diff <= 0
        },
        shortDate (dateStr) {
            return moment(dateStr, "YYYY-MM-DD @HH:mm").calendar()
        },
        iconColor (dateStr) {
            let diff = moment(dateStr, "YYYY-MM-DD @HH:mm").diff(moment(), "hours")
            if(diff < 24)
                return "red"
            else return ""
        },
    }
}
</script>
<style scoped>
.v-btn-toggle {
  flex-direction: column;
}
</style>
